import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const TemplateForm = ({ clientId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [config, setConfig] = useState({
    primaryColor: '',
    secondaryColor: '',
    contrastColor: '',
    backgroundColor: '',
    fontPrimary: '',
  });

  useEffect(() => {
    const fetchConfig = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: 'https://dev-v0oj1xh74t1zkydk.us.auth0.com/api/v2/', // Your API Audience
                scope: 'read:config', // Request only the required scope
            });
                        const response = await fetch(`/api/config/${clientId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setConfig(data);  // Update the form fields with the new data
        } catch (error) {
            console.error('Error fetching config:', error);
        }
    };

    fetchConfig();
}, [clientId, getAccessTokenSilently]);


  const handleChange = (e) => {
    setConfig({ ...config, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      await fetch(`/api/config/${clientId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(config),
      });
      alert('Configuration updated successfully.');
    } catch (error) {
      console.error('Error updating config:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Primary Color:
        <input type="text" name="primaryColor" value={config.primaryColor} onChange={handleChange} />
      </label>
      <label>
        Secondary Color:
        <input type="text" name="secondaryColor" value={config.secondaryColor} onChange={handleChange} />
      </label>
      <label>
        Contrast Color:
        <input type="text" name="contrastColor" value={config.contrastColor} onChange={handleChange} />
      </label>
      <label>
        Background Color:
        <input type="text" name="backgroundColor" value={config.backgroundColor} onChange={handleChange} />
      </label>
      <label>
        Font:
        <input type="text" name="fontPrimary" value={config.fontPrimary} onChange={handleChange} />
      </label>
      <button type="submit">Save Configuration</button>
    </form>
  );
};

export default TemplateForm;
