import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    // Redirect to the client portal after login or signup
    navigate(appState?.returnTo || '/client-portal');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin, // Use the new `authorizationParams.redirect_uri`
      }}
      onRedirectCallback={onRedirectCallback} // This handles redirection after signup/login
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
