import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';

const ClientPortal = () => {
  const { logout, user } = useAuth0();
  let isClosingWindow = false;

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    
    logout({
      returnTo: window.location.origin, // Redirect to the current origin (localhost or production)
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID, // Make sure the client ID is included
    });
  };
  
  

  useEffect(() => {
    // Set a flag in localStorage when the user logs in
    localStorage.setItem('isLoggedIn', 'true');

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        isClosingWindow = true;
      }
    };

    const handleBeforeUnload = (event) => {
      if (isClosingWindow) {
        handleLogout(); // Log out only if the window is being closed
        isClosingWindow = false;
      }
    };

    // Listen for visibility change to track if the user is navigating away
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Listen for beforeunload to handle window close
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Clean up the event listeners on component unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [logout]);

  return (
    <div>
      <h2>Client Portal</h2>
      <p>Welcome, {user?.name}</p>
      <ul>
        <li><Link to="/template">Template Design</Link></li>
        <li>Subscription & Billing</li>
      </ul>
      {/* Logout button triggers the handleLogout function */}
      <LogoutButton onClick={handleLogout} />
    </div>
  );
};

export default ClientPortal;
