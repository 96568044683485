import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

const WaitlistForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    restaurant: '',
    tooCheap: '',
    tooExpensiveMonthly: '',
    almostTooExpensive: '',
    tooExpensiveOneTime: '',
    highMonthIntake: '',
    lowMonthIntake: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form data:', formData); // Logging form data before submission
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/form-submissions`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Form submitted successfully:', response.data); // Logging successful submission
      alert('Form submitted successfully.');
      window.location.href = `${process.env.REACT_APP_FRONTEND_URL}/share`;
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an issue with your submission. Please try again.');
    }
  };

  return (
    <div className="waitlist-wrapper">
      <h1>Waitlist Form</h1>
      <form className="waitlist-form" onSubmit={handleSubmit}>
        {/* Form fields here */}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default WaitlistForm;
