import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import TemplateForm from './TemplateForm'; // Import the TemplateForm component

const TemplateDesign = () => {
  const { user, isAuthenticated } = useAuth0();
  
  if (!isAuthenticated) {
    return <div>Please log in to edit your template.</div>;
  }

  return (
    <div>
      <h2>Welcome, {user.name}</h2>
      <TemplateForm clientId={user.email} /> {/* Use client email or other identifier */}
    </div>
  );
};

export default TemplateDesign;
