import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import ClientPortal from './ClientPortal';
import TemplateDesign from './TemplateDesign'; // Ensure this is imported
import Pay from './Pay'; // Import the Pay component
import ProtectedRoute from './ProtectedRoute';
import Share from './Share'; // New share page
import WaitlistForm from './WaitlistForm';

function App() {
  return (
    <div className="App-wrapper">
      <div className="App">
        <header className="App-header">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/client-portal"
              element={
                <ProtectedRoute>
                  <ClientPortal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/template"
              element={
                <ProtectedRoute>
                  <TemplateDesign />
                </ProtectedRoute>
              }
            />
            <Route path="/waitlist" element={<WaitlistForm />} />
            <Route path="/share" element={<Share />} />
            {/* Add the route for the payment page */}
            <Route path="/pay" element={<Pay />} />
          </Routes>
        </header>
      </div>
    </div>
  );
}

export default App;
